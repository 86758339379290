<template>
  <div>
    <el-table :data="users" style="width: 90%; margin: 5%;" highlight-current-row @current-change="handleCurrentTableChange">
      <el-table-column label="序号" type="index" width="50" />
      <el-table-column label="用户名" prop="user" />
      <el-table-column label="比分指数">
        <template #default="scope">
          <div style="">
            <span>{{scope.row.bifenzhishu}}</span>
            <el-input-number v-if="currentUserid === scope.row.userid" v-model="bifenzhishuDays" @change="bifenzhishuHandleChange" size="mini"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="进球模型">
        <template #default="scope">
          <div style="">
            <span>{{scope.row.jinqiumoxing}}</span>
            <el-input-number v-if="currentUserid === scope.row.userid" v-model="jinqiumoxingDays" @change="jinqiumoxingHandleChange" size="mini"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="比分逻辑">
        <template #default="scope">
          <div style="">
            <span>{{scope.row.bifenluoji}}</span>
            <el-input-number v-if="currentUserid === scope.row.userid" v-model="bifenbifenluojiDays" @change="bifenluojiHandleChange" size="mini"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="比分模型走DI（精）">
        <template #default="scope">
          <div style="">
            <span>{{scope.row.bifenModelDI}}</span>
            <el-input-number v-if="currentUserid === scope.row.userid" v-model="bifenModelDIDays" @change="bifenModelDIHandleChange" size="mini"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="right">
        <template #header>
          <el-input placeholder="搜索内容" v-model="search" clearable>
            <template #append>
              <el-button :icon="Search" @click="searchButton"></el-button>
            </template>
          </el-input>
        </template>
        <template #default="scope">
          <div style="display: flex;">
            <el-button size="mini" type="primary" @click="handleConfirm(scope.$index, scope.row)">确认</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange"></el-pagination>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
// import wx from 'weixin-jsapi'
// import wx from 'wxwork-jsapi'
// import wx from 'http://res.wx.qq.com/open/js/jweixin-1.2.0.js'
// const wx = require('weixin-js-sdk')
export default {
  data () {
    return {
      search: '',
      userid: '',
      users: [],
      total: 0,
      curPage: 1,
      currentUserid: '',
      bifenzhishuDays: 0,
      jinqiumoxingDays: 0,
      bifenModelDIDays: 0,
      bifenluojiDays: 0,
      pageSize: 20,
      item: ''
    }
  },
  created () { // 创建Vue时就执行
    // alert(this.$route.params.code)
    // let urls
    const that = this
    let params = window.location.href.split('?')[1] // 获取浏览器输入地址
    params = params.replace('code=', '')
    console.log(window.location.href)
    const code = params.split('&')[0]
    console.log(code)
    this.$axios.get('api/wwx/getuserinfo', {
      params: {
        code: code
      }
    }).then(res => {
      console.log(res)
      if (!res.data.errcode) {
        that.userid = res.data.userid
        that.getUsers()
        /* that.$axios.get('api/wwx/wwxconfig', {
          params: {
            url: 'http://mengshizhijia.com/'
          }
        }).then(res => {
          console.log(res.data)
          wx.config({
            beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId, // 必填，企业微信的corpID
            timestamp: res.data.timeStamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: ['getContext'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
          })
          wx.ready(res => {
            console.log(res)
            wx.invoke('getContext', {}, res => {
              alert(JSON.stringify(res))
              if (res.err_msg === 'getContext:ok') {
                // entry = res.entry // 返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
                // shareTicket = res.shareTicket // 可用于调用getShareInfo接口
                console.log(res.entry)
              } else {
                console.log('err')
              }
            })
          })
          wx.error(err => { // 验证失败
            // alert('wx error: ' + JSON.stringify(err))
            console.log(err)
          })
        }) */
      }
    })
  },
  mounted () {
    /* var ua = window.navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) === 'micromessenger') {
      // alert( true );
    } else {
      alert('请用企业微信打开！')
      window.location.replace('http://mengshizhijia.com')
    } */
    // wx.ready(() => {
    // })
  },
  methods: {
    getUsers () {
      this.$axios.get('api/wwx/admin/users', {
        params: {
          search: this.search,
          pageSize: 20,
          curPage: this.curPage
        }
      }).then(res => {
        console.log(res)
        this.users = res.data.users
        this.total = res.data.total
      })
    },
    handleCurrentTableChange (val) {
      this.currentUserid = val.userid
    },
    handleConfirm (index, row) {
      this.$axios.post('api/wwx/admin/users', {
        bifenzhishuDays: this.bifenzhishuDays,
        jinqiumoxingDays: this.jinqiumoxingDays,
        bifenModelDIDays: this.bifenModelDIDays,
        bifenluojiDays: this.bifenluojiDays,
        userid: this.currentUserid
      }).then(res => {
        console.log(res)
        if (!res.data.errcode) {
          ElMessage({
            message: this.data.errmsg,
            type: 'success'
          })
        }
      })
    },
    currentChange (page) {
      this.curPage = page
      this.getUsers()
    },
    bifenzhishuHandleChange (value) {
      this.bifenzhishuDays = value
      console.log('bifenzhishuDays', value)
    },
    jinqiumoxingHandleChange (value) {
      this.jinqiumoxingDays = value
      console.log('jinqiumoxingDays', value)
    },
    bifenModelDIHandleChange (value) {
      this.bifenModelDIDays = value
      console.log('bifenModelDIDays', value)
    },
    bifenluojiHandleChange (value) {
      this.bifenluojiDays = value
      console.log('bifenluojiDays', value)
    },
    searchButton () {
      this.curPage = 1
      this.getUsers()
    }
  }
}
</script>
